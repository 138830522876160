import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { useRecoilState } from 'recoil';
import { ToolTipMarker } from '../marker';
import MethodPracticeCard from '../methodPracticeCard';
import { mapState } from './mapState';

import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';

const USE_LOGO_ZOOM_LEVEL = 8;
const DEFAULT_CENTER_COORDINATES = { lat: 2.95, lng: -50.33 };
const DIALOG_BUFFER = { x: 200, y: 200 };

let googleMap = null;
let googleMaps = null;

const Map = ({ initZoom, methodPracticeMarkers }) => {
  const [zoom, setZoom] = useState(initZoom);
  const [markersShowInfoCard, setMarkersShowInfoCard] = useState({});
  const [googleApiReady, setGoogleApiReady] = useState(!!googleMap && !!googleMaps);
  const [openInfoCardKey, setOpenInfoCardKey] = useState(null);
  const [methodPracticeMarkersPerWorkSite, setMethodPracticeMarkersPerWorkSite] = useState({});
  const [mapStateData, setMapState] = useRecoilState(mapState);
  const useLogo = zoom >= USE_LOGO_ZOOM_LEVEL;
  const { initialBounds } = mapStateData;
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const handleApiLoaded = (map, maps) => {
    googleMap = map;
    googleMaps = maps;
    googleMaps.event.addListener(googleMap, 'zoom_changed', () => {
      const newZoom = googleMap.getZoom();
      if (newZoom != zoom) {
        setZoom(newZoom);
      }
    });
    setGoogleApiReady(true);
    setMapState((prevState) => {
      return !prevState.loaded 
      ? { ...prevState, map, maps, loaded: true } 
      : { ...prevState };
    });
  };

  useEffect(() => {
    if (googleApiReady && googleMaps && googleMap) {
      if (!isEmpty(initialBounds)) {
        googleMap.fitBounds(initialBounds);   
      } else {
        const bounds = new googleMaps.LatLngBounds();
        let newMarkerPerWorkSite = {};
        methodPracticeMarkers.forEach((methodPractice) => {
          if (
            has(methodPractice, ['worksiteId', 'latitude']) &&
            methodPractice.worksiteId.latitude != 0 &&
            has(methodPractice, ['worksiteId', 'longitude']) &&
            methodPractice.worksiteId.longitude != 0
          ) {
            // Extend map bounds if we are not just going to pan to My Location
            bounds.extend({
              lat: parseFloat(methodPractice.worksiteId.latitude),
              lng: parseFloat(methodPractice.worksiteId.longitude)
            });
            if (!has(newMarkerPerWorkSite, methodPractice.worksiteId.id)) {
              newMarkerPerWorkSite[methodPractice.worksiteId.id] = [];
            }
            newMarkerPerWorkSite[methodPractice.worksiteId.id].push(methodPractice);
          }
        });
        setMethodPracticeMarkersPerWorkSite(newMarkerPerWorkSite);
        if (!isEmpty(newMarkerPerWorkSite)) {
          googleMap.fitBounds(bounds);
        }
      }
      if (isFirstLoad && !isEmpty(methodPracticeMarkers)) {
        googleMap.panTo(new googleMaps.LatLng(DEFAULT_CENTER_COORDINATES.lat, DEFAULT_CENTER_COORDINATES.lng ));
        setIsFirstLoad(false);
      }
    }
  }, [googleApiReady, methodPracticeMarkers, initialBounds]);

  const mpWorkSiteKeys = Object.keys(methodPracticeMarkersPerWorkSite);
  const newMarkersToRender = [];
  mpWorkSiteKeys.forEach((worksiteId) => {
    const showInfoCard = has(markersShowInfoCard, worksiteId)
      ? markersShowInfoCard[worksiteId]
      : false;
    const hoverText = has(methodPracticeMarkersPerWorkSite[worksiteId][0], ['organizationId', 'name'])
      ? methodPracticeMarkersPerWorkSite[worksiteId][0].organizationId.name
      : undefined;
    const coordinates = { 
      lat: parseFloat(methodPracticeMarkersPerWorkSite[worksiteId][0].worksiteId.latitude),
      lng: parseFloat(methodPracticeMarkersPerWorkSite[worksiteId][0].worksiteId.longitude),
    };
    newMarkersToRender.push(
      <ToolTipMarker
        key={worksiteId}
        isOrg={false}
        showInfoCard={showInfoCard}
        onMarkerClick={(currentShowInfoCard) => {
          // We are toggling the currentShowInfoCard value
          const newShowInfoCard = !currentShowInfoCard;
          const newMarkersShowInfoCard = { ...markersShowInfoCard, [worksiteId]: newShowInfoCard };
          // Close other open InfoCards
          if (worksiteId != openInfoCardKey) {
            newMarkersShowInfoCard[openInfoCardKey] = false;
          }
          setMarkersShowInfoCard(newMarkersShowInfoCard);
          // Set new openInfoCardKey
          setOpenInfoCardKey(newShowInfoCard ? worksiteId : null);
          // Pan to clicked marker
          const offsetCoordinates = {lat: coordinates.lat, lng: coordinates.lng};
          googleMap.panTo(offsetCoordinates);
          // Try to pan a bit more veritically to better center dialog
          googleMap.panBy(DIALOG_BUFFER.x, DIALOG_BUFFER.y);
        }}
        onInfoCardClose={() => {
          setMarkersShowInfoCard({ ...markersShowInfoCard, [worksiteId]: false });
          setOpenInfoCardKey(null);
        }}
        infoCard={
          <MethodPracticeCard methodPractices={methodPracticeMarkersPerWorkSite[worksiteId]} />
        }
        logoPath={
          useLogo &&
          has(methodPracticeMarkersPerWorkSite[worksiteId][0], ['organizationId', 'logo'])
            ? methodPracticeMarkersPerWorkSite[worksiteId][0].organizationId.logo
            : undefined
        }
        hoverText={hoverText}
        { ...coordinates }
      />
    );
  });
  return (
    <div className="map" style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        zoom={zoom}
        defaultCenter={DEFAULT_CENTER_COORDINATES}
      >
        {newMarkersToRender}
      </GoogleMapReact>
    </div>
  );
};

Map.propTypes = {
  orgMarkers: PropTypes.array,
  methodPracticeMarkers: PropTypes.array,
  initZoom: PropTypes.number,
};
Map.defaultProps = {
  initZoom: 4,
  orgMarkers: [],
  methodPracticeMarkers: [],
};
export default Map;
